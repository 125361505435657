// --- CONTAINERS
@mixin module {max-width: 1060px; margin: 0 auto;}
// --- SIZE
@mixin size($width, $height) {
      width: $width;
      height: $height;
}
// --- SPACING
@mixin padbox($padbox) {
	padding: $padbox; box-sizing: border-box;
}
@mixin padblock($padblock) {
	padding: $padblock; box-sizing: border-box; display: inline-block;
}
// --- POSITION
@mixin full0 {
	top: 0; bottom: 0; left: 0; right: 0;
}
@mixin full-fixed {
	position: fixed; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; overflow-y: scroll;
}
@mixin full-modal {
  position: fixed; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, 0.6); z-index: 9;
  @include flexbox; @include flow-cn; @include just-center; @include items-center; visibility: hidden; opacity: 0;
}
// --- BORDER
@mixin radius($radius) {
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}
@mixin inset {
  box-shadow: 0px 0px 1px #333 inset;
}
// --- PREFIX $pref
@mixin prefix($property, $value, $vendors: webkit moz ms o) {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $property}: #{$value};
        }
      }
      #{$property}: #{$value};
}
@mixin transform($property) {
  @include prefix(transform, $property, $pref);
}
// --- TRANSITION
@mixin trans {
	@include transx(0.3s);
}
@mixin transx($sec) {
	-webkit-transition: all $sec ease;
	-moz-transition: all $sec ease;
	-o-transition: all $sec ease;
	-ms-transition: all $sec ease;
	transition: all $sec ease;
}
@mixin transition($all, $sec, $ease) {
	-webkit-transition: $all $sec $ease;
	-moz-transition: $all $sec $ease;
	-o-transition: $all $sec $ease;
	-ms-transition: $all $sec $ease;
	transition: $all $sec $ease;
}
// --- MEDIA (proyect specific)
@mixin deskOffice {
  @media screen and (min-width: 1900px) {@content;}
}
@mixin retina {
  @media screen and (min-width: 1600px) {@content;}
}
@mixin imac {
  @media screen and (min-width: 1400px) {@content;}
}
@mixin mac {
  @media screen and (min-width: 1200px) {@content;}
}
@mixin desk {
  @media screen and (min-width: 1024px) {@content;}
}
@mixin tablet {
  @media screen and (min-width: 760px) {@content;}
}
@mixin ipad {
  @media screen and (min-width: 760px) and (max-width: 1024px) {@content;}
}
@mixin phone {
  @media screen and (min-width: 0px) and (max-width: 760px) {@content;}
}
@mixin ipod {
  @media screen and (min-width: 0px) and (max-width: 320px) {@content;}
}
@mixin default {
  @media screen and (min-width: 0px) {@content;}
}
@mixin ie11 {
  @media all and (-ms-high-contrast:none) {@content;}
}
// -- ANIMATION
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
//fadeIn_open
@include keyframes(fadein_open) {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
// -- FORMS
@mixin no-form {-webkit-appearance: none; -moz-appearance: none; appearance: none;
  border: none; outline: none; display: inline-block; background-color: transparent; @include radius(0px);
}
@mixin form-check($appearance) {-webkit-appearance: $appearance; -moz-appearance: $appearance; appearance: $appearance;}
@mixin placeholder($color) {
	&::placeholder {color: $color; opacity: 1;}
	&::-webkit-input-placeholder {color: $color; opacity: 1;}
	&::-moz-input-placeholder {color: $color; opacity: 1;}
	&:-moz-input-placeholder {color: $color; opacity: 1;}
	&:-ms-input-placeholder {color: $color; opacity: 1;}
}
// -- SHORTHANDS

// -- SCROLL
@mixin scrollbar($color) {
	@include mobile {
		// & {-webkit-overflow-scrolling: touch;}
		&::-webkit-scrollbar-track:horizontal {
			border-radius: 6px;
			background-color: #F5F5F5;
			height: 3px;
		}
		&::-webkit-scrollbar-track:vertical {
			background-color: transparent;
			width: 0px;
		}
		&::-webkit-scrollbar {
      -webkit-appearance: none;
			height: 3px;
			&:horizontal {height: 3px;}
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 6px;
			background-color: $color;
		}
	}
}
// --- HOVER
@mixin hover {
  &:hover {@content;}
}
@mixin hovers {
	&:hover, &:active, &:focus {@content;}
}
@mixin fxbottom($bgcolor) {position: relative;
	&::after {content: ""; position: absolute; left: 50%; right: 50%; bottom: 0; height: 2px; background-color: $bgcolor;
		@include prefix(transition-property, (left, right), $pref);
		@include prefix(transition-timing-function, ease, $pref);
		@include trans;
	}
	&:hover::after {left: 0; right: 0;}
}
@mixin fxsweep($bgcolor, $color) {
	& {
		display: inline-block; vertical-align: middle; box-shadow: 0 0 1px transparent; position: relative;
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		-webkit-transition-property: color;
		transition-property: color;
		-webkit-transition-duration: 0.1618s;
		transition-duration: 0.1618s;
	}
	&::before {
		background-color: $bgcolor;
		content: ""; position: absolute; z-index: -1;
		top: 0; left: 0; right: 0; bottom: 0;
		-webkit-transform: scaleY(0); transform: scaleY(0);
		-webkit-transform-origin: 50% 0; transform-origin: 50% 0;
		-webkit-transition-property: transform; transition-property: transform;
		-webkit-transition-duration: 0.1618s; transition-duration: 0.1618s;
		-webkit-transition-timing-function: ease-out; transition-timing-function: ease-out;
	}
}
@mixin fxopen($bg, $color) {
	& {
		display: inline-block; vertical-align: middle; position: relative;
		-webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0);
		-webkit-transition-property: color; transition-property: color;
		-webkit-transition-duration: 0.3s; transition-duration: 0.3s;
	}
	&::before {
		content: "";
		position: absolute; z-index: -1;
		top: 0; bottom: 0; left: 0; right: 0;
		background-color: $bg;
		-webkit-transform: scaleY(0); transform: scaleY(0);
		-webkit-transform-origin: 50%; transform-origin: 50%;
		-webkit-transition-property: transform; transition-property: transform;
		-webkit-transition-duration: 0.3s; transition-duration: 0.3s;
		-webkit-transition-timing-function: ease-out; transition-timing-function: ease-out;
	}
	&:hover {
		color: $color;
		&::before {
			-webkit-transform: scaleY(1);
			transform: scaleY(1);
		}
	}
}

// --- GRADIENTS
@mixin grad-x($left, $middile, $right, $angle) {
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' $left ', endColorstr=' $right ', GradientType=1 );
	background: -moz-linear-gradient(left, $left 0%, $middile $angle, $right 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, $left), color-stop($angle, $middile), color-stop(100%, $right));
	background: -webkit-linear-gradient(left, $left 0%, $middile $angle, $right 100%);
	background: -o-linear-gradient(left, $left 0%, $middile $angle, $right 100%);
	background: -ms-linear-gradient(left, $left 0%, $middile $angle, $right 100%);
	background: linear-gradient(to right, $left 0%, $middile $angle, $right 100%);
}
@mixin grad-y($top, $middle, $bottom, $angle) {
	//filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=' $top ', endColorstr=' $bottom ', GradientType=0 );
	background: -moz-linear-gradient(top, $top 0%, $middle $angle, $bottom 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, $top), color-stop($angle, $middle), color-stop(100%, $bottom));
	background: -webkit-linear-gradient(top, $top 0%, $middle $angle, $bottom 100%);
	background: -o-linear-gradient(top, $top 0%, $middle $angle, $bottom 100%);
	background: -ms-linear-gradient(top, $top 0%, $middle $angle, $bottom 100%);
	background: linear-gradient(to bottom, $top 0%, $middle $angle, $bottom 100%);
}

// --- LOOK
@mixin grayscale($level) {
  -webkit-filter: grayscale($level); filter: grayscale($level);
}

// ------------------------- CUSTOM

// buttons
@mixin btn-go {
  @include padblock(0.5em 1em); background-color: $red; color: white; border: 1px solid $red; font-weight: 300;
  @include radius(3px); text-align: center;
  span {
    &::after {content: "\f105"; font-family: $icon; padding-left: 6px; display: inline-block; @include trans;}
  }
  &:hover span::after {@include transform(translateX(6px));}
}
@mixin btn-back {
  @include padblock(0.5em 1em); background-color: white; color: $blue; border: 1px solid $blue; font-weight: 300;
  @include radius(3px); text-align: center;
  span {
    &::before {content: "\f104"; font-family: $icon; padding-right: 6px; display: inline-block; @include trans;}
  }
  &:hover span::before {@include transform(translateX(-6px));}
}

// options
@mixin box-border {
  border-color: $blue; box-shadow: 0px 0px 0px 1px $blue inset;
}

// forms
@mixin selectbox {width: 100%; position: relative;
  select {@include padblock(0.5em 3.5em 0.5em 0.5em); color: $grey; border: 1px solid $light; background-color: transparent;}
  &::after {content: "\f0d7"; font-family: $icon; @include padblock(0.3em 0.5em); border-left: 1px solid $light;
    position: absolute; top: 0; bottom: 0; right: 0; z-index: -1; font-size: 1.25em; max-height: 38px;
  }
}
@mixin btn-before {
  & span::before {content: "\f104"; font-family: $icon; display: inline-block; padding-right: 6px; @include trans;}
  &:hover span::before {@include transform(translateX(-6px));}
}
@mixin btn-after {
  & span::after {content: "\f105"; font-family: $icon; display: inline-block; padding-left: 6px; @include trans;}
  &:hover span::after {@include transform(translateX(6px));}
}
